import { render, staticRenderFns } from "./catalog-item-hover-slider2.vue?vue&type=template&id=41b29535&scoped=true&"
import script from "./catalog-item-hover-slider2.vue?vue&type=script&lang=js&"
export * from "./catalog-item-hover-slider2.vue?vue&type=script&lang=js&"
import style0 from "./catalog-item-hover-slider2.vue?vue&type=style&index=0&id=41b29535&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41b29535",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureComponent: require('/home/d/didisher2/ac-avangard.rf/www/components/picture/picture-component.vue').default,ButtonFavorite: require('/home/d/didisher2/ac-avangard.rf/www/components/button/button-favorite.vue').default})
